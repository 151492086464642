import React from 'react';
import '../../App.css';
import Training1 from '../Training1';
import TrainingTech from '../TrainingTech';
import Aptispoken from '../Aptispoken';



function Training() {
    return(
    <> 
     <Training1 />
     <TrainingTech />
     <Aptispoken />
     
    </>

    );
}

export default Training