// import React from "react";
// import '../App.css';
// import Trainimg from '../assets/Train1img.jpg';
// import './Training1.css';
// import Trainingimg1 from '../assets/Train2img.png';

// function Training1() {
//     return(
//       <>
//         <div className="Training-Container">
//           <div className="Training-body"> 
//           <ul>
//             <h2 className="Trainingh2title"> Training </h2> <br/>
//             <li> <b> 12 months Intense and Focused</b> Training to deliver <b>top quality freshers</b> to the  IT industry </li> <br/>
//             <li> Finalized based on extensive discussions with <b>Industry Professionals</b></li>
//           </ul>
//           </div>
//           <div className="Training-pic">
//           <img src={Trainimg} alt='uspic'/>  
//           </div>

//         </div>

//         <div>
//             <div className="Training-Container1">
//               <div className="Training-pic1">
//                 <img src={Trainingimg1} alt='uspic'/>  
//               </div>
//           <div className="Training-body1"> 
//           <ul>
//             <li> Focuses both on <b>Conceptual Clarity </b>as well as extensive <b>Hands-on Practical exposure</b></li>
//             <li> Regular<b> Evaluations, Feedback, Mentoring</b> and <b> Course Corrections </b></li>
//           </ul>
//           </div>


//       </div>
 
//         </div>
//       </>    
      
//     );

// }

// export default Training1

import React from "react";
import '../App.css';
import Trainimg from '../assets/Train1img.jpg';
import './Training1.css';
import Trainingimg1 from '../assets/Train2img.png';
import coursera from '../assets/Coursera-Logo.png';
import englishbolo from '../assets/englishbolo.jpg';
import doselect from '../assets/doselect.jpg';
import hackerrank from '../assets/hackerrank.jpg';
import sixphrase from '../assets/sixphras.png';

function Training1() {
    return (
        <>  
            <h1 className="Trainingh2title">Training</h1> <br />
            <div className="aptispoken-image-container">
                <img src={coursera} alt="I1" className="aptispoken-image" />
                <img src={englishbolo} alt="I2" className="aptispoken-image" />
                <img src={doselect} alt="I3" className="aptispoken-image" />
                <img src={hackerrank} alt="I4" className="aptispoken-image" />
                <img src={sixphrase} alt="I5" className="aptispoken-image" />
                
            </div>
            <div className="Training-Container">
                <div className="Training-body">
                    <ul>
                       
                        <li><b>12 months Intense and Focused</b> Training to deliver <b>top quality freshers</b> to the IT industry</li> <br />
                        <li>Finalized based on extensive discussions with <b>Industry Professionals</b></li>
                    </ul>
                </div>
                <div className="Training-pic">
                    <img src={Trainimg} alt='uspic' />
                </div>
            </div>

            <div>
                <div className="Training-Container1">
                    <div className="Training-pic1">
                        <img src={Trainingimg1} alt='uspic' />
                    </div>
                    <div className="Training-body1">
                        <ul>
                            <li>Focuses both on <b>Conceptual Clarity</b> as well as extensive <b>Hands-on Practical exposure</b></li>
                            <li>Regular <b>Evaluations, Feedback, Mentoring</b>, and <b>Course Corrections</b></li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Training1;
