import React from 'react';
import '../../App.css';
import Studentshead from '../Studentshead';
import Studentheadsection from '../Studentheadsection';

function Students() {
    return(
     <>
      <Studentshead />
      <Studentheadsection />
      
     </>
    );
}

export default Students