// import React from 'react';
// import '../App.css';
// import './HeroSection.css';
// import image from '../assets/heroimg.jpg';

// function HeroSection() {
//   return (
//     <div className='hero-container'>
//       <img src={image} alt='pic' />
//       <div className='hero-content'>
//         <p>A Charitable Trust that provides Training and Placement Assistance exclusively to undergraduate students in Tier 2 and Tier 3 Colleges, from Extremely Challenged Financial backgrounds  </p>
//       </div>
//     </div>
//   );
// }

// export default HeroSection;

import React from 'react';
import '../App.css';
import './HeroSection.css';
import himage from '../assets/heroimg.jpg';

function HeroSection() {
  return (
    <div className='herosection-container'>
      
      <img src={himage} alt='pic' className='hpic'/>
      

      <div className='hero-content'>
        <p>A Charitable Trust that provides Training and Placement Assistance exclusively to undergraduate students in Tier 2 and Tier 3 Colleges, from Extremely Challenged Financial backgrounds  </p>
      </div>
    </div>
  );
}

export default HeroSection;





