import React from "react";
import './Studentheadsection.css';
import Studentheadpic1 from '../assets/Studentheadsectionpic.jpg';
import Studentheadsec from '../assets/Studentheadsection1.jpg';


function Studentheadsection() {
    return (
        <>
        <div className="Studenthead-Container1">
            <div className="Studenthead-body1">
                <ul className="Studenthead-ul1">
                    <li> All our students hail from small towns or villages, and from families faced with extreme financial challenges  </li>
                    <li> They are meritorious in their academics, with a minimum CGPA of 7.0, with most of them being highly driven and hungry for work and success   </li>
                    <li> On an average, their families have 5 members - with only the father being an earning member. In most cases, the father would either be a small farmer, or a daily wage worker, or in some other menial job   </li>
                    <li> Typically, the income of these families would range between a minimum of Rs 50,000/- per annum to a maximum of Rs 300,000/- per annum, with most of them under significant burden of loans   </li>
                    <li> Often these students are the first in their whole family lineage to have ventured into a college, and so they face a  serious deficit of mentoring and guidance   </li>
                </ul>
            </div>
            <div className="Studenthead-pic1">
                <img src={Studentheadpic1} alt='uspic'/>
            </div>

        </div>
        <div>
            <div className="Studenthead-Container2">
                <div className="Studenthead-pic2">
                    <img src={Studentheadsec} alt='uspic' />
                </div>
                <div className="Studenthead-body2">
                    <ul className="Studenthead-ul2">
                     <li> Their colleges would typically be a Tier 3 college, ill equipped  to make them job-ready and assist in their placements  </li>
                     <li> Good companies seldom hire from these colleges, and most of these colleges have either inadequate or negligible campus placements  </li>
                     <li> So with education loans to repay, these students face the grim prospect of finding a job for themselves after their undergraduate studies </li>
                     <li> Many of them struggle for months, and often end up with jobs paying them less than Rs 10,000/- pm - thus resulting in no significant change to the pitiable financial condition of their families </li>
                     <li> All in all, they face severe and extremely unfair odds against even reasonable chance of success, and quite simply need help from their more fortunate counterparts in society   </li>
                    </ul>
                </div>
            </div>

        </div>
                
                
    </>

    );
}


export default Studentheadsection