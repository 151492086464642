// import React from "react";
// import './Internshiptitle.css';
// import interntitlepic1 from '../assets/1111.jpg';
// import pacewisdonlogo1 from '../assets/pwstitllogo.svg';

// function Internshiptitle() {
//     return (
//       <>
//          <h2 className="internshiptitleh2"> The Pacewisdom - Swapnodaya Internship Program </h2>
//          <img src={interntitlepic1} alt='pic1' className="internpic1"/>
//          <div>
//             <div className="internship-Container1">
//               <div className="internship-logo">
//                 <img src={pacewisdonlogo1} alt='uspic'/>  
//               </div>
//              <div className="internship-body1"> 
//              <h3> About Pacewisdom</h3>
//               <ul>
//                <li> Swapnodaya provides its students with a high quality internship through Pacewisdom, a Tech Services company based out of Bangalore</li>
//                <li> Pacewisdom is a Tech solutions provider that has delivered over 150 cutting edge solutions across 10 countries spanning several industry verticals <a href="https://pacewisdom.com/">(www.pacewisdom.com)</a> </li>
//                <li> Pacewisdom’s dynamic and socially responsible management team has  come forward in support of Swapnodaya’s social cause of uplifting and empowering financially challenged, yet meritorious, students by making them eminently employable and fully job-ready </li>
//               </ul>
//              </div>
//           </div>
//          </div>
//          <div className="internulsub">
         
//          <ul>
//          <h3 className="duration">Duration and Content</h3>
//             <li>Towards this end, Pacewisdom and Swapnodaya have put together a unique Internship  Model customized for Swapnodaya’s goals, called the  “Pacewisdom-Swapnodaya Upskill Program”</li>
//             <li>The program involves 480 hours of hands-on practical work (much more than what most other students get to do over a 6 month full time internship) covering the effective use and application of Technology tools like Python, SQL, DSA, C++, Front End Technologies, Github etc</li>
//             <li>Students execute a number of projects, and upload them into their Git accounts.  Examples of the projects : Resume  Builder, Kanban Board, Internet Connection Status, QR Code Generator etc</li>
//          </ul>
//          </div>

             
//       </>
//     );

// }
// export default Internshiptitle;

import React from "react";
import './Internshiptitle.css';
import interntitlepic1 from '../assets/1111.jpg';
import pacewisdonlogo1 from '../assets/pwstitllogo.svg';

function Internshiptitle() {
    return (
      <>
        <h1 className="internshiptitleh2"> The Pacewisdom - Swapnodaya Internship Program </h1>
        <img src={interntitlepic1} alt='pic1' className="internpic1"/>
        <div className="internship-Container1">
          <div className="internship-logo">
            <img src={pacewisdonlogo1} alt='uspic'/>  
          </div>
          <div className="internship-body1"> 
            <h3> About Pacewisdom</h3>
            <ul>
              <li> Swapnodaya provides its students with a high-quality internship through Pacewisdom, a Tech Services company based out of Bangalore</li> 
              <li> Pacewisdom is a Tech solutions provider that has delivered over 150 cutting-edge solutions across 10 countries spanning several industry verticals <a href="https://pacewisdom.com/">(www.pacewisdom.com)</a> </li>
              <li> Pacewisdom’s dynamic and socially responsible management team has come forward in support of Swapnodaya’s social cause of uplifting and empowering financially challenged, yet meritorious, students by making them eminently employable and fully job-ready </li>
            </ul>
          </div>
        </div>
        <div className="internulsub">
          <h3 className="duration">Duration and Content</h3>
          <ul>
            <li>Towards this end, Pacewisdom and Swapnodaya have put together a unique Internship Model customized for Swapnodaya’s goals, called the “Pacewisdom-Swapnodaya Upskill Program”</li>
            <li>The program involves 480 hours of hands-on practical work (much more than what most other students get to do over a 6-month full-time internship) covering the effective use and application of Technology tools like Python, SQL, DSA, C++, Front End Technologies, Github etc</li>
            <li>Students execute a number of projects and upload them into their Git accounts. Examples of the projects: Resume Builder, Kanban Board, Internet Connection Status, QR Code Generator, etc</li>
          </ul>
        </div>
      </>
    );
}

export default Internshiptitle;
