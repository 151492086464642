import React from 'react';
import './ModelCard.css'; 

function ModelCard() {
  return (
        <>
        <div className='Ourmodel_container'>

        
         <div className='ourmodel'>
          <h5>OUR MODEL</h5> <br/>
          <h1>How we help</h1>
         
          </div>
         <div className="Modelcard-container">

        <div className="Modelcard">
          
        <h3>STEP #1</h3>
          <p><b>Identify</b> deserving students through entrance tests and personal interviews.</p>
          <h5>..</h5>
        </div>
         
        <div className="Modelcard">

          <h3>STEP #2</h3>
          <p> <b>Train </b>extensively on technology tools, aptitude & spoken english.</p>
          <a href='/Training' className="learn-more">Learn More <span>&rarr;</span> </a>
       
        </div>
        <div className="Modelcard">

          <h3>STEP #3</h3>
          <p>Provide the right <b>Internships</b> for practical exposure. </p>
          <a href='/Internship' className="learn-more">Learn More <span>&rarr;</span> </a>

        </div>
        <div className="Modelcard">

          <h3>STEP #4</h3>
          <p> Connect the students with companies for <b>Placements</b>.</p>
          <a href='/Placements' className="learn-more">Learn More <span>&rarr;</span> </a>

        </div>
      </div>
      </div>
      </>
    
    
  
  );
};

export default ModelCard;

