import React from "react";
import '../../App.css';
import Aboutus from '../Aboutus';

function About() {
    return (
        <Aboutus />
    );
}

export default About;