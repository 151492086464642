import React from "react";
import './Aptispoken.css';
import aptiimage from '../assets/aptispoken.jpg';


function Aptispoken() {
    return(
         <>

            
         <div className="aptimain">
         
         <div className="apticontainer">
            <div className="top">
               
                <div className="AptSpoken">
                    <h1 className="vv"> Aptitude </h1>
                    <p className="bb"> [Duration : 100 hours] </p>

                </div>
                <div className="Apti">
                    <h1 className="mm"> Spoken English </h1>
                    <p className="nn"> [Duration : 300 hours] </p>

                </div>

            </div>
            {/* <div className="aptiimg">
                <img src={aptiimage} alt="im1" />

            </div> */}

            

        </div>

        <div className="aptiimg">
                <img src={aptiimage} alt="im1" />

            </div>
        </div>
        
        </>

         
    );
}

export default Aptispoken;

