import React from "react";
import './Placement.css';
import placementhead1 from '../assets/placement1.jpeg'
import celebration from '../assets/celebration.jpg';
import deloitte from '../assets/deloitte 1.jpg';
import pacewisdom from '../assets/pwstitllogo.svg';
import siemens from '../assets/siemens_healtheeners_logo-1200x500 1.jpg';
import thoughtprocess from '../assets/thoughtprocess.jpeg';
import canopus from '../assets/canopus 1.png';
import practo from '../assets/Practo-logo-healthcare-startups 1.jpg';

function Placement() {
    return(
           <>
           <h1 className="placementtitle">Placements</h1>
           <div className="placementbody">
           <div className="placement-image-container">
             <img src={deloitte} alt="I1" className="Gallery-image" />
             <img src={pacewisdom} alt="I2" className="Gallery-image" />
             <img src={siemens} alt="I3" className="Gallery-image" />
             <img src={practo} alt="I4" className="Gallery-image" />
             <img src={thoughtprocess} alt="I5" className="Gallery-image" />
             <img src={canopus} alt="I5" className="Gallery-image" />
           </div>
           
           <div className="Placement-Container1">
            
            
            <div className="placement-body1">
                <ul className="placement-ul1">
                <li> Given their socio-economic backgrounds, the Swapnodaya students tend to be more humble and attach a higher value to their jobs. Therefore, they present a real prospect of reduced attrition to their employers. Further, they are generally more driven and hard working. </li>
                    <li> Upskilled quite comprehensively through the intense, year long Swapnodaya Training program, they indeed represent high quality fresh talent</li>
                    <li> Swapnodaya has so far placed its students in a number of reputed companies like<b> Deloitte, Siemens, Pacewisdom, Canopus, Thought Process and Practo </b></li>
                </ul>
            </div>
            <div className="placement-pic1">
                <img src={placementhead1} alt='uspic' className="placement-pic1" />
            </div>

        </div>
        <div>
            <div className="placement-Container2">
                <div className="placement-pic2">
               
                    <img src={celebration} alt='uspic'  />
                </div>
                <div className="placement-body2">
                    <ul className="placement-ul2 ">
                       <li> Discussions are underway with several other reputed Tech companies, and Swapnodaya expects to significantly widen the basket of companies for the placement of its current cohort</li>
                       <li> All the students placed till now have performed very well in their work, and their employers are fully satisfied</li>
                       <li> Swapnodaya is grateful to these companies and its key decision makers, who have come forward to support Swapnodaya’s cause of uplifting and empowering the weak and severely challenged sections of our society</li>
                    </ul>
                </div>
            </div>
            
           </div>
        </div>  

       


        </>
    );
}

export default Placement;


