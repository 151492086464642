import React from "react";
import body1img from '../assets/TrainTechimg1.jpg';
import body2img from '../assets/TrainTechimg2.jpg';
import body3img from '../assets/TrainTechimg3.jpg';
import './TrainingTech.css';


function TrainingTech () {
  return (
    <>
    <h2 className="techtitle">The broad details of the Training program are as under:</h2>
    
    
    <div className="Traintech-main-container">
          <div>
            <h1> Training in Technology</h1>
            <p>[Duration : 1000 hours]</p>
          </div>
          <div className="Traintech-container">
          <img src={body1img} alt="Ima1" className="bodypic1" />
          <img src={body2img} alt="Ima2" className="bodypic2" />
          <img src={body3img} alt="Ima3" className="bodypic3" />
          </div>
      </div>

    </>
  );
}


export default TrainingTech



    

