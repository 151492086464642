import React from "react";
import Internshiptitle from '../Internshiptitle';
import Internshipteam from '../Internshipteam';

function Internship() {
    return(
        <>
          <Internshiptitle />
          <Internshipteam />
        </>
    );
}
export default Internship