import React from "react";
import { Link } from 'react-router-dom';
import './SupportUs.css';
import supportimg from '../assets/supportus.jpg';

function SupportUs() {
    return ( 
      <>
      <div className="Support-Container">
            <div className="supportimg">
              <img src={supportimg} alt='supportimg' />
            </div>
                 
      
            <div className="Support-body">
              <h5>SUPPORT US</h5> <br/>
              <h1>How you can help</h1><br/><br/>
              <p>Your support could make a huge difference, if you are:</p><br/><br/>
              <ul className="Support-list">
                  <li>A company willing to evaluate and employ our students</li><br/>
                  <li>A professional willing to spare some of your time to further our cause</li><br/>
                  <li>An entity/individual willing to make a financial contribution to strengthen our cause</li><br/><br/>
              </ul>
              <div className="button-container">
              <Link to="/Contact"><button className="supportbutton">Support Us</button> </Link>
              </div>
            </div>
      </div>
      
         
      
      </>  
    );
}
export default SupportUs;

