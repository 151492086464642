import React from "react";
import './Internshipteam.css';
import teampic from '../assets/pacewisdomteam.png';


function Internshipteam() {
    return (
       
            <div className="Internshipteam-Container1">
              <div className="Internshiphead-Containerpic">
                <img src={teampic} alt='uspic'/>  
              </div>
            
             <div className="Internshiphead-body1"> 
              <ul className="pacewisdomlogo">
                <h3> Evaluation and Hiring</h3>
                <li> The performance of the students on these projects is monitored and assessed by Pacewisdom Managers, with regular mentoring inputs - making the students industry ready </li> 
                <li> Pacewisdom also hires from the Swapnodaya cohort, to meet its own needs of fresh Tech talent </li>
                <li> Swapnodaya’s commitment to bring about perceptible change in both society and the Tech industry stands strengthened manifold by Pacewisdom’s association with its cause as a Patron and Training partner </li>
              </ul>
             </div>
            </div>
            
        

    );
}

export default Internshipteam







