import React from "react";
import './App.css';
import Home from "./Components/Pages/Home";
import Navbar from "./Components/Navbar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Training from './Components/Pages/Training';
import Students from "./Components/Pages/Students";
import Internship from "./Components/Pages/Internship";
import Placements from "./Components/Placement";
import About from "./Components/Pages/About";
import Contact from "./Components/Pages/Contact";

function App() {
  return (
    <>
    <Router>
      <Navbar/>
      <Routes>      
      <Route exact path='/' element={<Home />} />
      <Route exact path='/Training' element={<Training />} />
      <Route exact path='/Internship' element={<Internship />} />
      <Route exact path='/Students' element={<Students />} />
      <Route exact path='/Placements' element={<Placements />} />
      <Route exact path='/Contact' element={<Contact />} />
      <Route exact path='/About' element={<About />} /> 
      </Routes>
    </Router>
    </>
  );
}

export default App;
