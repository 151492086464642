import React, { useEffect, useState } from 'react';
import './ContactUs.css';
import submitimg from '../assets/404-tick.png';
import { Link } from 'react-router-dom';
import emailjs from 'emailjs-com';



const Form = () => {

  const [isPopupVisible, setPopupVisible] = useState(false);
  const [emailData, setEmailData] = useState({
    you_are: "Individual",

    individual_type: " ",
    organisation_type: " ",
    // volunteer_type: "Volunteer",
    organisation_name: "-NA-",
    designation: "-NA-",
    // hire_organazation: "",
    hire_Fc1: "",
    title: "Mr.",
    first_name: '',
    last_name: '',
    // hire_designation: '',
    // fc_designation: '',
    // hire_Fc1_designation: '',
    contact: "",
    fc_amount: '',
    // fc1_amount: '',
    email: "",
    message: "",

  });

  const handleSubmit = (e) => {
    sendMail()
    e.preventDefault();
    setPopupVisible(true);
  };
 

  const [showPopup, setShowPopup] = useState(false);
  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const [showhide, setShowhide] = useState('');
  const handleshowhide = (event, key) => {
    const getuser = event.target.value;
    debugger
    setEmailData({ ...emailData, [key]:event.target.text})
    
    setShowhide(getuser);

  };

  const sendMail = () => {
    console.log("EMAILDATA")
    console.log(emailData)
    emailjs.send("service_rt7oa1m", "template_rnpsz4d", emailData, "CyAjjpZ5ljWq-C5mb")
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
  }
  useEffect(() => {
      setShowhide('Individual')
  }  , [])


  return (
    <>
      <div className="form-container">
        <form className="contact-form" onSubmit={handleSubmit}>
          <h2 className='contacttitle'> Get in touch with Swapnodaya</h2>
          <label htmlFor="usertype"> You are </label>
          <select id="utype" name="utype" required onChange={(e) => (handleshowhide(e, 'you_are'))}>
            <option value="Individual">An individual</option>
            <option value="Company">An organization</option>
          </select>
          {
            ['Individual', 'Student', 'Volunteer', 'FinancialContribution', 'Other'].includes(showhide) && (
              <div className='Individualname'>
                <select id="utype" name="utype" required onChange={(e) => (handleshowhide(e, 'individual_type'))}>
                  <option value="Student">Student who wants Swapnodaya help</option>
                  <option value="Volunteer">Want to Volunteer for Swapnodaya work</option>
                  <option value="FinancialContribution">Want to make a Financial Contribution</option>
                  <option value="Other">Others</option>
                </select>
              </div>
            )
          }



          {
            ['Company','Hire', 'Financialcontribution1', 'Other1'].includes(showhide) && (
              <div className='Companyname'>
                <select id="utype" name="utype" required onChange={(e) => (handleshowhide(e, 'organisation_type'))}>
                  <option value="Hire">Want to Hire Swapnodaya Students </option>
                  <option value="Financialcontribution1">Want to make a Financial Contribution</option>
                  <option value="Other1">Others</option>
                </select>
              </div>
            )
          }
          {
            ['Hire', 'Other1', 'Company'].includes(showhide) && (
              <div className='hirestudent'>
                <label>Organization Name</label>
                <input type="text" id="hirestudent" name="hirestudent" required onChange={(e) => setEmailData({ ...emailData, hire_organazation: e.target.value })} />

              </div>
            )
          }
          {
            showhide === 'Financialcontribution1' && (
              <div className='Fc1'>
                <label>Organization Name</label>
                <input type="text" id="Fc1" name="Fc1" required onChange={(e) => setEmailData({ ...emailData, hire_Fc1: e.target.value })} />

              </div>
            )
          }

          <label htmlFor="title">Mr/Ms/Mrs</label>
          <select id="title" name="title" required onChange={(e) => setEmailData({ ...emailData, title: e.target.value })}>
            <option value="Mr.">Mr.</option>
            <option value="Mrs.">Ms.</option>
            <option value="Ms.">Mrs.</option>
          </select>
          <label htmlFor="firstName">First Name</label>
          <input type="text" id="firstName" name="firstName" required onChange={(e) => setEmailData({ ...emailData, first_name: e.target.value })} />
          <label htmlFor="lastName">Last Name</label>
          <input type="text" id="lastName" name="lastName" required onChange={(e) => setEmailData({ ...emailData, last_name: e.target.value })} />
          {
             ['Hire', 'Other1', 'Company'].includes(showhide) && (
              <div className='hirestudent'>
                <label>Designation</label>
                <input type="text" id="hirestudent" name="hirestudent" required onChange={(e) => setEmailData({ ...emailData, hire_designation: e.target.value })} />

              </div>
            )
          }

          
          {
            showhide === 'Financialcontribution1' && (
              <div className='Fc1'>
                <label>Designation</label>
                <input type="text" id="Fc1" name="Fc1" required onChange={(e) => setEmailData({ ...emailData, hire_Fc1_designation: e.target.value })} />

              </div>
            )
          }
          <label htmlFor="contact">Contact Number</label>
          <input type="tel" id="contact" name="contact" required pattern="[0-9]{3}[0-9]{3}[0-9]{4}" placeholder='xxxxxxxxxx' onChange={(e) => setEmailData({ ...emailData, contact: e.target.value })} />
          <label htmlFor="email">Email</label>
          <input type="email" id="email" name="email" placeholder='example@gmail.com' required onChange={(e) => setEmailData({ ...emailData, email: e.target.value })} />
          {
            showhide === 'FinancialContribution' && (
              <div className='FC'>
                <label>Proposed Contribution Amount</label> 
                <input type="text" id="FC" name="FC" required onChange={(e) => setEmailData({ ...emailData, fc_amount: e.target.value })} />
                <p>Minimum contribution is Rs 20,000/- , which is our estimated cost of training and upskilling one student for 2024-25</p>

              </div>
            )
          }
          {
            showhide === 'Financialcontribution1' && (
              <div className='Fc1'>
                <label>Proposed Contribution Amount</label>
                <input type="text" id="Fc1" name="Fc1" required onChange={(e) => setEmailData({ ...emailData, fc1_amount: e.target.value })} />
                <p>Minimum contribution is Rs 20,000/- , which is our estimated cost of training and upskilling one student for 2024-25</p>

              </div>
            )
          }
          <label htmlFor="message">Please type your message here and we will contact you</label>
          <textarea id="message" name="message" required onChange={(e) => setEmailData({ ...emailData, message: e.target.value })}></textarea>
          <button type="submit" >Submit</button>



        </form>
        {isPopupVisible && (
          <div className="popup">
            <img src={submitimg} alt='submit img' />
            <h2>Thank You!</h2>
            <p>Your application has been submitted successfully.</p>
            <button className="close-popupbutton" onClick={togglePopup}> <Link to="/"> Close</Link> </button>
          </div>

        )}
      </div>
      <script src="https://smtpjs.com/v3/smtp.js">  </script>


    </>


  );
};

export default Form;

