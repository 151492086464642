import React from 'react';
import '../App.css';
import './Vision.css';

function Vision() {
  return (
    <div className='vision'>
      <h1 className='button-vision'>VISION</h1>
      <p className='vision-para'>
        <i>
          “To become a one-stop Training and Placement platform for the underprivileged, yet meritorious, students studying in the lesser-known Tier 2 and 3 colleges that are scattered across India's semi-urban and rural areas”
        </i>
      </p>
    </div>
  );
}

export default Vision;
