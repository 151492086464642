// import React, { useState } from 'react';
// import './CardSlider.css';

// const CardSlider = () => {
//   const cardData = [
//     { id: 1, title: 'Lavanya', image: 'l3.jpg', info: 'College - Dr.AIT Bengaluru(2022)', info1: '"Swapnodaya was a Godsend for me. But for Swapnodaya’s training and placements assistance, I would not be working for a top MNC today. Swapnodaya handheld my journey through this whole process like a guide and coach"'},
//     { id: 2, title: 'Sushmitha' ,image:'sushmith1.jpg', info: 'College - Dr.AIT Bengaluru(2022)', info1: '"Swapnodaya did not just upskill us in Tech subjects, but dealt with us like a friendly and trusted mentor, always encouraging us and boosting our self confidence. I consider it my good fortune that I could do the Swapnodaya program"'},
//     { id: 3, title: 'Neelambika' ,image:'Sw.jpg', info: 'College - Dr.AIT Bengaluru(2022)', info1: '"Swapnodaya opened a whole new world of opportunities for me, not just by upskilling me, but also by connecting me with top companies who do not come to my college for placements. Swapnodaya has uplifted my life and empowered me"'},
//     { id: 4, title: 'Gurukiran  A C' ,image:'gurukiran.jpg', info: 'College - Dr.AIT Bengaluru(2023)', info1: '"Swapnodaya treated us like family. Not just their IT upskilling, but also their training in Spoken English and Aptitude, as well as all their mock interviews helped me immensely in landing my dream job. Thanks Swapnodaya"'},
//     { id: 5, title: 'Vidysharee G R' ,image:'vidyashree.jpg', info: 'College - GMIT Maddur(2023)', info1: '"If today I work in a Global Consulting firm, it is only due to Swapnodaya. Coming from a small town, I had very low on exposure and confidence. Swapnodaya not only fixed these problems, but also provided me with great placement opportunities"' },
//     { id: 6, title: 'Chaitanya Swaroop' ,image:'swaroop.jpg', info: 'College - GMIT Maddur(2023)',info1: '"The Swapnodaya model of Training focused more on practical work was outstanding. The 300 hours of intense work on practical projects through the online Upskill platform was invaluable. It gave me the confidence that I can join any company and contribute well"'},
    
//   ];

//   const cardsPerPage = 3;

//   const [currentPage, setCurrentPage] = useState(0);

//   const handleNextClick = () => {
//     if (currentPage < cardData.length - 1) {
//       setCurrentPage((prevPage) => prevPage + 1);
//     }
//   };

//   const handlePrevClick = () => {
//     if (currentPage > 0) {
//       setCurrentPage((prevPage) => prevPage - 1);
//     }
//   };

//   const startIndex = currentPage;
//   const endIndex = Math.min(startIndex + cardsPerPage, cardData.length);
//   const visibleCards =
//     startIndex <= endIndex
//       ? cardData.slice(startIndex, endIndex)
//       : [...cardData.slice(startIndex), ...cardData.slice(0, endIndex)];

//   return (
//     <>
//       <h1 className="testtitle">Progress and Testimonials</h1>
//           <div className='testsubtitle'>
//                 <p>
//                     <li>Since our inception in 2021, we have so far upskilled around 50 students, and are currently working with a batch of 110 students for the year 2023-24</li>
//                     <br/>
//                     <li>Upskilled students have been successfully placed in companies like Deloitte, Siemens Healthineers, Canopus, Pacewisdom, Thought Process etc</li>
//                 </p>
//           </div>      
//       <div className="card-slider">
//         <div
//           className={`navigation prev ${currentPage === 0 ? 'disabled' : ''}`}
//           onClick={handlePrevClick}
//         >
//           &lt;
//         </div>

//         {visibleCards.map((card) => (
//           <div className="card" key={card.id}>
//             <img className="card-img" src={card.image} alt={card.title} />
            
            
//             <p className="info1">{card.info1}</p>
//             <h4 className="cardh">{card.title}</h4>
//             <h5 className="info">{card.info}</h5>
//           </div>
//         ))}

//         <div className={`navigation next ${
//             currentPage >= Math.ceil(cardData.length / cardsPerPage) - 1
//               ? 'disabled' : ''
//           }`}
//           onClick={handleNextClick}> &gt;
//         </div>

//         <div className={`navigation prev ${
//             currentPage >= Math.ceil(cardData.length / cardsPerPage) - 1
//               ? 'disabled' : ''
//           }`}
//           onClick={handlePrevClick}> &lt;
//         </div>

//       </div>
//     </>
//   );
// };

// export default CardSlider;

// import React from "react";
// import Slider from 'react-slick';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
// import './TestimonialsCard.css';
    
        
           
// const testimonialsData = [
//   {
//     name: 'John Doe',
//     designation: 'CEO, Company Inc',
//     review: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
//     image: 'vaani.jpg', 
//   },
//   {
//     name: 'John Doe',
//     designation: 'CEO, Company Inc',
//     review: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
//     image: 'vaani.jpg', 
//   },
  
// ];

// const TestimonialsCard = () => {
//   const sliderSettings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//   };

//   return (
//     <div className="CardSlider-Container">
//     <div className="Slider-left">
//       <h5>WHAT HAVE WE DONE?</h5> <br/><br/>
//       <h1>Our progress thus far</h1> <br/><br/>
//       <p>Since Swapnodaya's inception in 2021, the Trust so far upskilled around <b>40 students</b>, and is currently working with a batch of 110 students for the year 2023-24.</p> <br/><br/>
//       <p> Upskilled students have been successfully placed in companies like<b> Deloitte, Siemens Healthineers, Canopus, Pacewisdom, Thought Process</b> etc.</p>
//     </div>
//     <div className="Slider-right">

//     <div className="testimonials-card">
//       <Slider {...sliderSettings}>
//         {testimonialsData.map((testimonial, index) => (
//           <>
//           <div key={index} className="testimonial-item">
//             <div className="testimonial-image">
//               <img src={testimonial.image} alt={testimonial.name} /> 
//             </div>
//             <div className="testimonial-content">
//               <div className="testimonials-header">
//                 <h3>{testimonial.name}</h3> <br/>
//                 <p>{testimonial.designation}</p> 
//               </div>
//             </div>

//           </div>
//           <div className="review-h5">
//               <h5>{testimonial.review}</h5>
//           </div>
//           </>
          
//         ))}
//       </Slider>
//     </div>
//     </div>
//   </div>
//   );
// };

// export default TestimonialsCard;

import React from "react";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './TestimonialsCard.css';



const testimonialsData = [
  {
    name: 'Lavanya',
    designation: 'Data Analyst, Deloitte Bengaluru',
    review: 'Swapnodaya was a Godsend for me. But for Swapnodaya’s training and placements assistance, I would not be working for a top MNC today. Swapnodaya handheld my journey through this whole process like a guide and coach',
    image: 'l2.jpeg', 
  },
  {
    name: 'Sushmitha',
    designation: 'Data Analyst, Deloitte Bengaluru',
    review: 'Swapnodaya did not just upskill us in Tech subjects, but dealt with us like a friendly and trusted mentor, always encouraging us and boosting our self confidence. I consider it my good fortune that I could do the Swapnodaya program',
    image: 'sushmith1.jpg', 
  },
  {
    name: 'Neelambika',
    designation: 'Data Analyst, Deloitte Bengaluru',
    review: 'Swapnodaya opened a whole new world of opportunities for me, not just by upskilling me, but also by connecting me with top companies who do not come to my college for placements. Swapnodaya has uplifted my life and empowered me',
    image: 'Sw.jpg', 
  },
  {
    name: 'Nisarga',
    designation: 'Software Engineer Trainee, Pacewisdom Mangalore',
    review: 'Our skills in practical computing were very low. Swapnodaya not only upskilled us, but also followed it up with an intense Internship with an IT company, which eventually hired me',
    image: 'nisarga.jpg', 
  },
  {
    name: 'Ranjitha M R',
    designation: 'Junior Software Engineer, Canopus BGS Bengaluru',
    review: 'The way the Swapnodaya team worked for our training and placements was really amazing. We were taken through mock interviews before every interaction with the officials of companies who evaluated us for hiring. I have a good job today, and that is solely because of Swapnodaya',
    image: 'ranjitha.jpg', 
  },
  {
    name: 'Gurukiran A C',
    designation: 'Data Analyst, Deloitte Bengaluru',
    review: 'Swapnodaya treated us like family. Not just their IT upskilling, but also their training in Spoken English and Aptitude, as well as all their mock interviews helped me immensely in landing my dream job. Thanks Swapnodaya',
    image: 'gurukiran.jpg', 
  },
  {
    name: 'Rakshitha H R',
    designation: 'Junior Software Engineer, Thought Process Systems LLP Bengaluru',
    review: 'The Swapnodaya upskilling program really upskilled us and instilled a lot of confidence in us. The 300 hour internship program was excellent. If I am happily employed today with good growth prospects, I would give the credit to Swapnodaya',
    image: 'rakshitha.jpeg', 
  },
  {
    name: 'Tejashwini',
    designation: 'Junior Software Engineer, Pacewisdom Mangaluru',
    review: 'My college too had arranged for practical training by outside firms. However, that was expensive and came with assurance of any job interview. Swapnodaya provided us with free training and connected us with several companies, one of which hired me.',
    image: 'tejashwini.jpg', 
  },
  {
    name: 'Nishanth Gowda',
    designation: 'Python Developer, Canopus BGS Bengaluru',
    review: 'Swapnodaya worked so hard with us for one full year to make us ready for Tech jobs, and also worked on our communication skills and aptitude. Thanks to Swapnodaya, I have found a good job even when most top IT firms had stopped hiring',
    image: 'nishanth.jpg', 
  },
  {
    name: 'Vidyashree',
    designation: 'Data Analyst, Deloitte Bengaluru',
    review: 'If today I am working in a Global Consulting firm, it is only and only due to Swapnodaya. Living and studying in a small town, I was low on exposure and confidence. Swapnodaya not only fixed these problems, but also provided me with great placement opportunities',
    image: 'vidyashree.jpg', 
  },
  {
    name: 'Poornima',
    designation: 'Junior Software Engineer, Pacewisdom Mangaluru',
    review: 'The training arranged by  Swapnodaya was of high quality, and absolutely free. After the completion of the training, Swapnodaya connected us with several companies for placements. Thanks to Swapnodaya, I am in a good job today, and enjoying my work',
    image: 'poornima.jpg', 
  },
  {
    name: 'Nithyshree',
    designation: 'Data Analyst, Deloitte Bengaluru',
    review: 'I will always be grateful to Swapnodaya. The way Swapnodaya worked with us and for us was really touching - almost like family. Thanks to Swapnodaya, I could not only upskill myself, but have also landed a great job for myself',
    image: 'nithyshree.jpeg', 
  },
  {
    name: 'Chaitanya Swaroop',
    designation: 'Data Analyst, Deloitte Bengaluru',
    review: 'The Swapnodaya model of Training focused more on practical work was outstanding. The 300 hours of intense work on practical projects through the online Upskill platform was invaluable. It gave me the confidence that I can join any company and contribute well',
    image: 'swaroop.jpg', 
  },
  {
    name: 'Monisha',
    designation: 'Data Analyst, Deloitte Bengaluru',
    review: 'The Swapnodaya training program, though free of cost, was intense and demanded a lot of hardwaor. In the end though, it was all worth it. I am much more confident about myself, and am placed in a MNC firm',
    image: 'monisha.jpg', 
  },
  {
    name: 'Darshini G B',
    designation: 'PLSQL Developer, Canopus BGS Bengaluru',
    review: 'The Swapnodaya program of upskilling and placement assistance was hugely helpful to me.  Not only did I get more clarity on key concepts, I also became so much more confident thanks to the practical training. And with respect to getting me placed too, Swapnodaya never stopped their efforts till such time I found the right job. Thanks Swapnodaya ',
    image: 'darshini.jpg', 
  },
  {
    name: 'Varun K S',
    designation: 'Intern, Thought Process Systems LLP Bengaluru',
    review: 'I am very grateful to Swapnodaya. It is my good luck that Swapnodaya chose to work in our college with financially challenged students. Their training was intense, but very useful and has made me job-ready. And with respect to placements too, Swapnodaya continuously created openings for us, and helped us prepare for every interview.',
    image: 'varun.jpg',
  },

  
];

const TestimonialsCard = () => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="CardSlider-Container container">
      <div className="Slider-left">
        <div className="slider-title">
          <h5>WHAT WE HAVE DONE THUS FAR</h5>  <br/> 
          <h1>Our progress</h1>
        </div>
        <p className="slider-paragrph">Since Swapnodaya's inception in 2021, the Trust so far upskilled around 40 students, and is currently working with a batch of 110 students for the year 2023-24.</p>
        <p className="slider-paragrph"> Upskilled students have been successfully placed in companies like Deloitte, Siemens Healthineers, Canopus, Pacewisdom, Thought Process etc.</p>
      </div>
      <div className="Slider-right">
        <div className="testimonials-card">
          <Slider {...sliderSettings}>
            {testimonialsData.map((testimonial, index) => (
              <>
                <div key={index} className="testimonial-item" >
                  <div className="testimonial-person">
                    <div className="testimonial-image">
                      <img src={testimonial.image} alt={testimonial.name} />
                    </div>
                    <div className="testimonial-content">
                      <div className="testimonials-header">
                        <h3>{testimonial.name}</h3>
                        <p>{testimonial.designation}</p>
                      </div>
                    </div>
                  </div>
                  <div className="review-h5">
                    <p>{testimonial.review}</p>
                  </div>
                </div>

              </>

            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default TestimonialsCard;
                
            
      
