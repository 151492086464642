import React from 'react';
import '../../App.css';
import HeroSection from '../HeroSection';
import Vision from '../Vision';
import TestimonialsCard from '../TestimonialsCard';
import SupportUs from '../SupportUs';
import ModelCard from '../ModelCard';

function Home() {
  return (
    <>
      <HeroSection />
      <Vision />
      <ModelCard />
      <TestimonialsCard  />
      <SupportUs />
             

    </>
  );
}

export default Home;

