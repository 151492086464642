import React from "react";
import './Studentshead.css';
import image from '../assets/Studentimg.jpg'

function Studentshead() {
    return (
        <>            
              <div className="Studenthead-container">  
                <img src={image} alt="pic" /> 
              </div>  
              <div className="Studenthead-title">
                <h1> Typical Student Profile and  Challenges</h1>
              </div>
        </>     
    );
}
export default Studentshead 