import React from "react";
import '../../App.css';
import ContactUs from '../ContactUs';

function Contact() {
    return (
        <ContactUs />
    );
}

export default Contact;